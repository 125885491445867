/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from "react";
import {Layout, Menu, Modal} from 'antd';
import {Content} from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import {useKeycloak} from "@react-keycloak/web";
import AllUsersTable from "./AllUsersTable";
import WaitingListTable from "./WaitingListTable";
import WorkflowEditorTable from "./WorkflowEditorTable";

export function AdminInterface() {
    const [selectedMenu, setSelectedMenu] = useState('editWorkflows');
    const [showWorkflowEditor, setShowWorkflowEditor] = useState(false);
    const [checkCloseWorkflowEditor, setCheckCloseWorkflowEditor] = useState(false);

    const keycloak = useKeycloak();

    function renderTableComponent() {
        switch (selectedMenu) {
            case 'allUsers':
                return <AllUsersTable/>;
            case 'waitingList':
                return <WaitingListTable/>;
            case 'editWorkflows':
                return <WorkflowEditorTable/>;
            default:
                return null;
        }
    }

    function showModal(text: string) {
        return new Promise((resolve) => {
            Modal.confirm({
                title: 'Confirm',
                content: text,
                onOk() {
                    resolve(true);
                },
                onCancel() {
                    resolve(false);
                },
            });
        });
    }

    async function onMenuClick(e: any) {
        if (checkCloseWorkflowEditor && showWorkflowEditor) {
            const result = await showModal('Are you sure you want to leave the Workflow Editor?');
            if (!result) return;
        }
        setShowWorkflowEditor(false);
        setCheckCloseWorkflowEditor(false);
        setSelectedMenu(e.key);
    }

    function getUserRoles() {
        return keycloak.keycloak.realmAccess?.roles || [];
    }

    function renderMenuItems() {
        const roles = getUserRoles();
        const menuItems = [];

        if (roles.includes('admin')) {
            menuItems.push(<Menu.Item key="allUsers">All Users</Menu.Item>);
        }

        if (roles.includes('admin')) {
            menuItems.push(<Menu.Item key="waitingList">Waiting List</Menu.Item>);
        }

        if (roles.includes('admin') || roles.includes('workflow-editor')) {
            menuItems.push(<Menu.Item key="editWorkflows">Workflows</Menu.Item>);
        }
        return menuItems;
    }

    const roles = getUserRoles();
    if (!roles.includes('admin') && !roles.includes('workflow-editor')) {
        window.location.href = '/denied';
        return null;
    }

    return (
        <Layout style={{minHeight: '100vh'}}>
            <Sider collapsible>
                <Menu theme="dark" selectedKeys={[selectedMenu]} mode="inline" onClick={onMenuClick}>
                    {renderMenuItems()}
                </Menu>
            </Sider>
            <Layout style={{padding: '0px !important'}}>
                <Content style={{margin: '0 ', padding: '0px !important', overflow: "auto"}}>
                    {renderTableComponent()}
                </Content>
            </Layout>
        </Layout>
    );
}
